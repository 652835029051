import { render, staticRenderFns } from "./addGood.vue?vue&type=template&id=146a6fe9&scoped=true&"
import script from "./addGood.vue?vue&type=script&lang=js&"
export * from "./addGood.vue?vue&type=script&lang=js&"
import style0 from "./addGood.vue?vue&type=style&index=0&id=146a6fe9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146a6fe9",
  null
  
)

export default component.exports